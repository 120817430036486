import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '../models/auth.models';
import { ApiService } from './api.service';
import { RoleLimit } from '../models/roleLimit';

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService extends ApiService<User> {
    private currentUserSubject: BehaviorSubject<User>;
    private userLoggedIn = new Subject<boolean>();
    private jwtHelper = new JwtHelperService();
    private loginModel: User = new User();
    public currentUser: Observable<User>;
    private actions: string[] = [];
    private limits: RoleLimit[] = [];
    private links: string[] = [];

    constructor(private http: HttpClient) {
        super(http, 'Auth');

        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.loginModel = new User();
        this.userLoggedIn.next(false);
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    can(action: string) {
        if (this.actions.length == 0) {
            this.actions = JSON.parse(localStorage.getItem('actions'));
        }

        const found = this.actions.filter(x => x == action);
        return (found.length > 0);
    }

    // canApprove(type: string, amount: number) {
    //     const limit = this.getLimit(type);
    //     console.log('limit required: ' + type + ' limit: ' + limit);

    //     if (limit == null) { return false; }
    //     if (limit.amount == null) { return true; }
    //     return (amount <= limit.amount);
    // }

    // canRelease(type: string, amount: number) {
    //     const limit = this.getLimit(type);

    //     if (limit == null) { return false; }
    //     if (limit.amount == null) { return true; }
    //     return (amount <= limit.amount);
    // }

    getLimit(type: string): RoleLimit {
        if (this.limits.length == 0) {
            this.limits = JSON.parse(localStorage.getItem('limits'));
        }

        const limits = this.limits.filter(x => x.limit.type == type);

        if (limits.length == 0) {
            return null;
        } else {
            return limits[0];
        }
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    linkMatch(link: string): boolean {
        if (link == '' || link == '/dashboard' || link == '/dashboardheadmaster' || link == '/dashboardschool' || link == '/dashboardteacher'  || link == '/dashboardparent'  || link == '/dashboardaccount' || link == '/dashboardloan') { return true; }

        // const found = this.links.filter(x => x == link);

        // return (found.length > 0);
    }

    login(email: string, password: string) {
        this.loginModel.username = email;
        this.loginModel.password = password;
        console.log("Hi Here");
        const res = super.postEndpoint('Login', this.loginModel);

        return res.pipe(map(
            (response: any) => {
                // login successful if there's a jwt token in the response
                if (response && response.token) {
                    console.log("Hi There");                          
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('roleName', response.roleName);
                    localStorage.setItem('currentUser', JSON.stringify(response));
                    localStorage.setItem('actions', JSON.stringify(response.actions));
                    localStorage.setItem('limits', JSON.stringify(response.limits));
                   // localStorage.setItem('links', JSON.stringify(response.links));

                    this.currentUserSubject.next(response);
                    this.actions = response.actions;
                    this.limits = response.limits;
                    this.userLoggedIn.next(true);
                    //this.links = response.links;
                }

                return response;
            }
        ));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('roleName');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('actions');
        localStorage.removeItem('limits');
        localStorage.removeItem('links');
        this.currentUserSubject.next(null);
        this.userLoggedIn.next(false);
    }

    isLoggedIn() {
        if (localStorage.getItem('currentUser') !== 'undefined') {
            const token = localStorage.getItem('token');

            if (this.jwtHelper.isTokenExpired(token)) {
                localStorage.removeItem('token');
                localStorage.removeItem('currentUser');

                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    resetPassword(id: number): Observable<any> {
        return this.postEndpoint(id + '/ResetPassword', []);
    }

    roleMatch(allowedRoles: Array<string>): boolean {
        return allowedRoles.includes(localStorage.getItem('roleName'));
    }

    // setUserLoggedIn(userLoggedIn: boolean) {
    //     this.userLoggedIn.next(userLoggedIn);
    // }
}

import { Injectable } from '@angular/core';
import { MenuItem } from 'src/app/layouts/horizontaltopbar/menu.model';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService extends ApiService<MenuItem> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'MenuItems');
  }
}

import { Component, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AlertifyService } from './core/services/notifications/alertify.service';
import { AuthfakeauthenticationService } from './core/services/authfake.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  constructor(
    private idle: Idle,
    private router: Router,
    private keepalive: Keepalive,
    protected modalService: NgbModal,
    private alertify: AlertifyService,
    private authService: AuthfakeauthenticationService
  ) {
    idle.setIdle(900);
    idle.setTimeout(15);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
      Swal.close();
    });

    idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.idleState = 'Timed out!';
      console.log(this.idleState);
      this.timedOut = true;
      this.logout();
      Swal.close();
      Swal.fire('Timed Out!', 'Your session has timed out and you need to log in again!', 'warning');
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ll be logged out in 15 seconds!';

      Swal.fire({
        title: 'Session Timing Out',
        text: this.idleState,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Keep me logged in!'
      }).then((result) => {
        if (result.value) {
          this.stay();
        }
      });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });

    // this.reset();
  }

  reset() {
    this.idle.watch();
    // xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  stay() {
    this.reset();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }
}

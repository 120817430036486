import { HttpHeaders, HttpClient } from '@angular/common/http';
import { List } from 'node_modulesA/@types/lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers : new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token')
  })
};

export abstract class ApiService<T> {
  basePath = environment.apiPath;
  apiURL = this.basePath + 'api/';
  //imagesPath = this.basePath + 'Resources/Avatars/';
  imagesPath = this.apiURL + 'Resources/Avatars/';

  // basePath = environment.apiUrl;
  // imagesFolder = 'Resources/Avatars/';
  // apiURL = this.basePath + 'api/';

  constructor(protected httpClient: HttpClient, protected actionUrl: string) { }

  public create(model: T): Observable<T> {
    console.log(this.apiURL + `${this.actionUrl}`);   

    return this.httpClient.post<T>(this.apiURL + `${this.actionUrl}`, model, httpOptions);
  }

  // public bulkCreate(model: List<T>): Observable<T> {
  //   console.log(this.apiURL + `${this.actionUrl}`);   

  //   return this.httpClient.post<List<T>>(this.apiURL + `${this.actionUrl}`, List<model>, httpOptions);
  // }
  public path() {
    return this.apiURL;
  }
  public update(id: number, model: T): any {
    return this.httpClient.put<T>(`${this.apiURL}${this.actionUrl}/${id}`, model, httpOptions);
  }
  // public updateList(id: number,model: List<T>): any {
  //   return this.httpClient.put<T[]>(`${this.apiURL}${this.actionUrl}/${id}', model, httpOptions);
  // }
  public delete(id: number): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiURL}${this.actionUrl}/${id}`, httpOptions);
  }

  public get(url?: string): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this.apiURL}${this.actionUrl}`, httpOptions);
  }

  public getUrl(url?: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}${this.actionUrl}/${url}`, httpOptions);
  }

  public getById(id: number): Observable<T> {
    return this.httpClient.get<T>(`${this.apiURL}${this.actionUrl}/${id}`, httpOptions);
  }

  public getEndpoint(endPoint?: string): Observable<T[]> {
    return this.httpClient.get<T[]>(`${this.apiURL}${this.actionUrl}/${endPoint}`, httpOptions);
  }

  public post(endPoint: string, model: any): Observable<any> {
    console.log(this.apiURL + `${this.actionUrl}/${endPoint}`);
    
    return this.httpClient.post(this.apiURL + `${this.actionUrl}/${endPoint}`, model, httpOptions);
  }

  public postEndpoint(endPoint: string, model: any): Observable<any> {
    console.log(this.apiURL + `${this.actionUrl}/${endPoint}`);
    console.log(model);
    
    return this.httpClient.post(this.apiURL + `${this.actionUrl}/${endPoint}`, model, httpOptions);
  }

  public updateUrl(endPoint: string, model: any) {
    return this.httpClient.post(this.apiURL + `${this.actionUrl}/${endPoint}`, model, httpOptions);
  }
}

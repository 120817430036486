import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { ShellComponent } from './shell/shell.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';


@NgModule({
  declarations: [ShellComponent, UnderConstructionComponent],
  imports: [
    CommonModule,
    UIModule,
  ],
  exports: [ShellComponent, UnderConstructionComponent],
})

export class SharedModule { }

import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';

import { DOCUMENT } from '@angular/common';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { environment } from '../../../environments/environment';
import { MenuItemService } from 'src/app/core/services/admin/menu-item.service';
import { SchoolEvent } from 'src/app/core/models/schoolEvent';
import { SchoolEventService } from 'src/app/core/services/schools/schoolEvent.service';
import { EmployeeService } from 'src/app/core/services/farming/employee.service';
import { Employee } from 'src/app/core/models/employee';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  element;
  configData;
  menuItems = [];
  fullName: string;
schoolEvent:SchoolEvent;
schoolEvents:SchoolEvent[]=[];
contracts: Employee[]=[];
showEvents = false;
showContracts = false;

jmenuAdministrator = {
  "id": 18,
  "username": "admin@royalcomfort.com",
  "password": "",
  "farmerId": 0,
  "companyId": 0,
  "transporterId": 0,
  "extensionOfficerId": 0,
  "studentId": 0,
  "employeeId": 8,
  "avatar": "AnyDesk.png",
  "fullName": "royal admin",
  "roleId": 7,
  "roleName": "Administrator",
  "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxOCIsInVuaXF1ZV9uYW1lIjoiYWRtaW5Acm95YWxjb21mb3J0LmNvbSIsImdyb3Vwc2lkIjoiNyIsImdpdmVuX25hbWUiOiJBZG1pbmlzdHJhdG9yIiwiZmFtaWx5X25hbWUiOiJSb3lhbEFkbWluIiwibmJmIjoxNjk4Mzk5NDM0LCJleHAiOjE2OTg0MjgyMzQsImlhdCI6MTY5ODM5OTQzNH0.lK8Lk88z8NR6GifKuPXlbaAqYLAqpZPhHyPgJyBC6gaCNYBVQcobWsLHLuVqzyrI7NllCsYFQ6JtW_ODq-u4zw",
  "menus": [
      {
          "id": 55,
          "label": "Administrator",
          "icon": "bx-tone",
          "link": "",
          "parentId": 20,
          "isUiElement": true,
          "index": 7,
          "isActive": true,
          "parent": {
              "id": 20,
              "label": "Setup",
              "icon": "",
              "link": "",
              "parentId": 12,
              "isUiElement": false,
              "index": 8,
              "isActive": true,
              "parent": {
                  "id": 12,
                  "label": "Dispatch Clerk",
                  "icon": "bx-tone",
                  "link": "",
                  "parentId": 20,
                  "isUiElement": true,
                  "index": 3,
                  "isActive": true,
                  "parent": null,
                  "subItems": null
              },
              "subItems": null
          },
          "subItems": [
              {
                  "id": 56,
                  "label": "Users Management",
                  "icon": "",
                  "link": "/administration/users",
                  "parentId": 55,
                  "isUiElement": false,
                  "index": 1,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 57,
                  "label": "Roles Management",
                  "icon": "",
                  "link": "/administration/roles",
                  "parentId": 55,
                  "isUiElement": false,
                  "index": 2,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 111,
                  "label": "Audit Trail",
                  "icon": "",
                  "link": "/administration/audit",
                  "parentId": 55,
                  "isUiElement": false,
                  "index": 50,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              }
          ]
      },
      {
          "id": 6120,
          "label": "School Learning Details",
          "icon": "",
          "link": "/schools",
          "parentId": 20,
          "isUiElement": true,
          "index": 131,
          "isActive": true,
          "parent": {
              "id": 20,
              "label": "Setup",
              "icon": "",
              "link": "",
              "parentId": 12,
              "isUiElement": false,
              "index": 8,
              "isActive": true,
              "parent": {
                  "id": 12,
                  "label": "Dispatch Clerk",
                  "icon": "bx-tone",
                  "link": "",
                  "parentId": 20,
                  "isUiElement": true,
                  "index": 3,
                  "isActive": true,
                  "parent": null,
                  "subItems": null
              },
              "subItems": null
          },
          "subItems": [
              {
                  "id": 6124,
                  "label": "Student Report",
                  "icon": "",
                  "link": "/schools/students-reports",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 134,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6125,
                  "label": "Student Assignment",
                  "icon": "",
                  "link": "/schools/students-assignments",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 135,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6126,
                  "label": "Student Submission",
                  "icon": "",
                  "link": "/schools/students-submissions",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 136,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7279,
                  "label": "Time Tables",
                  "icon": null,
                  "link": "/schools/time-tables",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 285,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7285,
                  "label": "Student Enrollment",
                  "icon": null,
                  "link": "/schools/students-enrollment",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 291,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7286,
                  "label": "Curriculum",
                  "icon": null,
                  "link": "/schools/curriculums",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 292,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7290,
                  "label": "Lesson Plans",
                  "icon": null,
                  "link": "/schools/lesson-plans",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 296,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7293,
                  "label": "Student Achievements",
                  "icon": null,
                  "link": "/schools/students-achievements",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 299,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7294,
                  "label": "Student Behaviours",
                  "icon": null,
                  "link": "/schools/students-behaviours",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 300,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7324,
                  "label": "Students Attendances",
                  "icon": null,
                  "link": "/schools/students-attendances",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 330,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7325,
                  "label": "Students Assignments",
                  "icon": null,
                  "link": "/schools/students-assignments",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 331,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7326,
                  "label": "Students Reports",
                  "icon": null,
                  "link": "/schools/students-reports",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 332,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7327,
                  "label": "Time Tables",
                  "icon": null,
                  "link": "/schools/time-tables",
                  "parentId": 6120,
                  "isUiElement": false,
                  "index": 333,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9386,
                  "label": "Employees",
                  "icon": null,
                  "link": "/schools/employee-profiles",
                  "parentId": 6120,
                  "isUiElement": null,
                  "index": 393,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9387,
                  "label": "Student Activities",
                  "icon": null,
                  "link": "/schools/student-activities",
                  "parentId": 6120,
                  "isUiElement": null,
                  "index": 394,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              }
          ]
      },
      {
          "id": 7175,
          "label": "School Reports",
          "icon": "",
          "link": "",
          "parentId": 20,
          "isUiElement": false,
          "index": 189,
          "isActive": true,
          "parent": {
              "id": 20,
              "label": "Setup",
              "icon": "",
              "link": "",
              "parentId": 12,
              "isUiElement": false,
              "index": 8,
              "isActive": true,
              "parent": {
                  "id": 12,
                  "label": "Dispatch Clerk",
                  "icon": "bx-tone",
                  "link": "",
                  "parentId": 20,
                  "isUiElement": true,
                  "index": 3,
                  "isActive": true,
                  "parent": null,
                  "subItems": null
              },
              "subItems": null
          },
          "subItems": [
              {
                  "id": 7176,
                  "label": "Employees By Institution",
                  "icon": "",
                  "link": "/school-reports/employees-by-school",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 190,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7177,
                  "label": "Payslips By Employee",
                  "icon": "",
                  "link": "/school-reports/payslips-by-employee",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 191,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7178,
                  "label": "Classes By School",
                  "icon": "",
                  "link": "/school-reports/cohorts-by-school",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 192,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7179,
                  "label": "Students By Class",
                  "icon": "",
                  "link": "/school-reports/students-by-cohort",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 193,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7180,
                  "label": "Student Reports By Student",
                  "icon": "",
                  "link": "/school-reports/student-reports-by-student",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 194,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7181,
                  "label": "Student Attendances By Student",
                  "icon": "",
                  "link": "/school-reports/student-attendances-by-student",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 195,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7266,
                  "label": "Outstanding Invoices By Cohort",
                  "icon": null,
                  "link": "/school-reports/outstanding-invoices-by-cohort",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 273,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7267,
                  "label": "Outstanding Invoices By Stream",
                  "icon": null,
                  "link": "/school-reports/outstanding-invoices-by-stream",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 274,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7268,
                  "label": "Outstanding Invoices By Student",
                  "icon": null,
                  "link": "/school-reports/outstanding-invoices-by-student",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 275,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7269,
                  "label": "Pass Rate By Cohort",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-cohort",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 276,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7270,
                  "label": "Pass Rate By Stream",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-stream",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 277,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7272,
                  "label": "Pass Rate By Streams",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-streams",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 278,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7273,
                  "label": "Pass Rate By Subject",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-subject",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 279,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7274,
                  "label": "Pass Rate By Subjects",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-subjects",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 280,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7275,
                  "label": "Pass Rate By Subjects By Streams",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-subjects-by-streams",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 281,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7276,
                  "label": "Pass Rate By Cohorts",
                  "icon": null,
                  "link": "/school-reports/pass-rate-by-cohorts",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 282,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7281,
                  "label": "Time Table By Class",
                  "icon": null,
                  "link": "/school-reports/time-table-by-class",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 287,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7282,
                  "label": "Time Tables By Classes",
                  "icon": null,
                  "link": "/school-reports/time-tables-by-classes",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 288,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7283,
                  "label": "Time Table By Teacher",
                  "icon": null,
                  "link": "/school-reports/time-table-by-teacher",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 289,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7284,
                  "label": "Time Tables By Teachers",
                  "icon": null,
                  "link": "/school-reports/time-tables-by-teachers",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 290,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8357,
                  "label": "Payslips By Payroll",
                  "icon": null,
                  "link": "/school-reports/payslips-by-payroll",
                  "parentId": 7175,
                  "isUiElement": false,
                  "index": 364,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9380,
                  "label": "Business Partners By Payroll",
                  "icon": null,
                  "link": "/school-reports/all-payslips-by-payroll",
                  "parentId": 7175,
                  "isUiElement": null,
                  "index": 387,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9382,
                  "label": "Time Table By Student",
                  "icon": null,
                  "link": "/school-reports/time-table-by-student",
                  "parentId": 7175,
                  "isUiElement": null,
                  "index": 389,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9383,
                  "label": "Time Tables By Students",
                  "icon": null,
                  "link": "/school-reports/time-tables-by-students",
                  "parentId": 7175,
                  "isUiElement": null,
                  "index": 390,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 9385,
                  "label": "Salary Schedule By Payroll",
                  "icon": null,
                  "link": "/school-reports/salary-schedule-by-payroll",
                  "parentId": 7175,
                  "isUiElement": null,
                  "index": 392,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              }
          ]
      },
      {
          "id": 7295,
          "label": "School Administration Details",
          "icon": null,
          "link": "/school-administration",
          "parentId": 20,
          "isUiElement": true,
          "index": 301,
          "isActive": true,
          "parent": {
              "id": 20,
              "label": "Setup",
              "icon": "",
              "link": "",
              "parentId": 12,
              "isUiElement": false,
              "index": 8,
              "isActive": true,
              "parent": {
                  "id": 12,
                  "label": "Dispatch Clerk",
                  "icon": "bx-tone",
                  "link": "",
                  "parentId": 20,
                  "isUiElement": true,
                  "index": 3,
                  "isActive": true,
                  "parent": null,
                  "subItems": null
              },
              "subItems": null
          },
          "subItems": [
              {
                  "id": 6122,
                  "label": "School",
                  "icon": "",
                  "link": "/school-administration/schools",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 132,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6123,
                  "label": "Student",
                  "icon": "",
                  "link": "/school-administration/students",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 133,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6127,
                  "label": "Subject",
                  "icon": "",
                  "link": "/school-administration/subjects",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 137,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6128,
                  "label": "Cohort",
                  "icon": "",
                  "link": "/school-administration/cohorts",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 138,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 6129,
                  "label": "Fees",
                  "icon": "",
                  "link": "/school-administration/fees",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 139,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7263,
                  "label": "Employee Attendances",
                  "icon": null,
                  "link": "/school-administration/employees-attendances",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 270,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7264,
                  "label": "Employee Performances",
                  "icon": null,
                  "link": "/school-administration/employees-performances",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 271,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7265,
                  "label": "Holding Company",
                  "icon": null,
                  "link": "/school-administration/holding-company",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 272,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7277,
                  "label": "Terms",
                  "icon": null,
                  "link": "/school-administration/terms",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 283,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7278,
                  "label": "Holidays",
                  "icon": null,
                  "link": "/school-administration/holidays",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 284,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7280,
                  "label": "Learning Facilities",
                  "icon": null,
                  "link": "/school-administration/learning-facilities",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 286,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7287,
                  "label": "Exam Candidates",
                  "icon": null,
                  "link": "/school-administration/exams-candidates",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 293,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7288,
                  "label": "Exam Enrollments",
                  "icon": null,
                  "link": "/school-administration/exams-enrollments",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 294,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7289,
                  "label": "Exam Time Tables",
                  "icon": null,
                  "link": "/school-administration/exams-time-tables",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 295,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7291,
                  "label": "School Events",
                  "icon": null,
                  "link": "/school-administration/schools-events",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 297,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7292,
                  "label": "Sporting Houses",
                  "icon": null,
                  "link": "/school-administration/sports-houses",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 298,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7349,
                  "label": "Cohort Subjects By Teacher",
                  "icon": null,
                  "link": "/school-administration/cohort-subject-teachers",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 355,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 7350,
                  "label": "Period Times",
                  "icon": null,
                  "link": "/school-administration/period-times",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 356,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8356,
                  "label": "Student Invoices",
                  "icon": null,
                  "link": "/school-administration/student-invoices",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 363,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8362,
                  "label": "Student Statement",
                  "icon": null,
                  "link": "/school-administration/student-statement",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 368,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8363,
                  "label": "Fee Type",
                  "icon": null,
                  "link": "/school-administration/fee-type",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 369,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8365,
                  "label": "Other Student Invoices",
                  "icon": null,
                  "link": "/school-administration/other-student-invoices",
                  "parentId": 7295,
                  "isUiElement": false,
                  "index": 371,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              },
              {
                  "id": 8377,
                  "label": "School Events",
                  "icon": null,
                  "link": "/school-administration/school-events",
                  "parentId": 7295,
                  "isUiElement": null,
                  "index": 383,
                  "isActive": true,
                  "parent": null,
                  "subItems": []
              }
          ]
      }
  ],
  "links": [
      "",
      "/administration/users",
      "/administration/roles",
      "/administration/audit",
      "/schools",
      "/schools/students-reports",
      "/schools/students-assignments",
      "/schools/students-submissions",
      "/schools/time-tables",
      "/schools/students-enrollment",
      "/schools/curriculums",
      "/schools/lesson-plans",
      "/schools/students-achievements",
      "/schools/students-behaviours",
      "/schools/students-attendances",
      "/schools/employee-profiles",
      "/schools/student-activities",
      "/school-reports/employees-by-school",
      "/school-reports/payslips-by-employee",
      "/school-reports/cohorts-by-school",
      "/school-reports/students-by-cohort",
      "/school-reports/student-reports-by-student",
      "/school-reports/student-attendances-by-student",
      "/school-reports/outstanding-invoices-by-cohort",
      "/school-reports/outstanding-invoices-by-stream",
      "/school-reports/outstanding-invoices-by-student",
      "/school-reports/pass-rate-by-cohort",
      "/school-reports/pass-rate-by-stream",
      "/school-reports/pass-rate-by-streams",
      "/school-reports/pass-rate-by-subject",
      "/school-reports/pass-rate-by-subjects",
      "/school-reports/pass-rate-by-subjects-by-streams",
      "/school-reports/pass-rate-by-cohorts",
      "/school-reports/time-table-by-class",
      "/school-reports/time-tables-by-classes",
      "/school-reports/time-table-by-teacher",
      "/school-reports/time-tables-by-teachers",
      "/school-reports/payslips-by-payroll",
      "/school-reports/all-payslips-by-payroll",
      "/school-reports/time-table-by-student",
      "/school-reports/time-tables-by-students",
      "/school-reports/salary-schedule-by-payroll",
      "/school-administration",
      "/school-administration/schools",
      "/school-administration/students",
      "/school-administration/subjects",
      "/school-administration/cohorts",
      "/school-administration/fees",
      "/school-administration/employees-attendances",
      "/school-administration/employees-performances",
      "/school-administration/holding-company",
      "/school-administration/terms",
      "/school-administration/holidays",
      "/school-administration/learning-facilities",
      "/school-administration/exams-candidates",
      "/school-administration/exams-enrollments",
      "/school-administration/exams-time-tables",
      "/school-administration/schools-events",
      "/school-administration/sports-houses",
      "/school-administration/cohort-subject-teachers",
      "/school-administration/period-times",
      "/school-administration/student-invoices",
      "/school-administration/student-statement",
      "/school-administration/fee-type",
      "/school-administration/other-student-invoices",
      "/school-administration/school-events"
  ]
};

jmenuAccountant = {
    "id": 400,
    "username": "funhiro@joburgpolytech.co.za",
    "password": "",
    "farmerId": 0,
    "companyId": 0,
    "transporterId": 0,
    "extensionOfficerId": 0,
    "studentId": 0,
    "employeeId": 239,
    "avatar": "NA",
    "fullName": "Martha FUNHIRO",
    "roleId": 2,
    "roleName": "Accountant",
    "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI0MDAiLCJ1bmlxdWVfbmFtZSI6ImZ1bmhpcm9Aam9idXJncG9seXRlY2guY28uemEiLCJncm91cHNpZCI6IjIiLCJnaXZlbl9uYW1lIjoiQWNjb3VudGFudCIsImZhbWlseV9uYW1lIjoiTWFydGhhRlVOSElSTyIsIm5iZiI6MTY5ODk4MjQwOSwiZXhwIjoxNjk5MDExMjA5LCJpYXQiOjE2OTg5ODI0MDl9.V7RDKTpMkMTvdECnzOaAqqHPAaVJHmYE3q3RI7MdGEPs46lFrhFlG5TQbTP7ofmo4nkXxGCL9-WwNyosELeGDQ",
    "menus": [
        {
            "id": 42,
            "label": "Accountant",
            "icon": "bx-tone",
            "link": "",
            "parentId": 20,
            "isUiElement": true,
            "index": 6,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 25,
                    "label": "Goods Receipt",
                    "icon": "",
                    "link": "/inventory/goods-receipt",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 1,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 70,
                    "label": "Request for Quotations",
                    "icon": "",
                    "link": "/purchase/quotations",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 1,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 71,
                    "label": "Purchase Requisitions",
                    "icon": "",
                    "link": "/purchase/requisitions",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 2,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 26,
                    "label": "Stock Valuation",
                    "icon": "",
                    "link": "/inventory/stock",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 2,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 72,
                    "label": "Purchase Orders",
                    "icon": "",
                    "link": "/purchase/orders",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 81,
                    "label": "Purchase Invoices",
                    "icon": "",
                    "link": "/purchase/invoices",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 80,
                    "label": "Account Transactions",
                    "icon": "",
                    "link": "/finance/other-transactions",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 4,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 30,
                    "label": "Manage Purchases Invoices",
                    "icon": "",
                    "link": "/finance/manage-purchases-invoice",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 6,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 31,
                    "label": "Sales/Fees Order",
                    "icon": "",
                    "link": "/inventory/sales-order",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 7,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 82,
                    "label": "Bank Reconciliation",
                    "icon": "",
                    "link": "/finance/bank-reconciliation",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 7,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 43,
                    "label": "Asset Register",
                    "icon": "",
                    "link": "/finance/asset-register",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 10,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 78,
                    "label": "Debtors",
                    "icon": "",
                    "link": "/finance/debtors",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 11,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 79,
                    "label": "Creditors",
                    "icon": "",
                    "link": "/finance/creditors",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 12,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 83,
                    "label": "Accounting Periods",
                    "icon": "",
                    "link": "/finance/accounting-periods",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 14,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 84,
                    "label": "Journaling",
                    "icon": "",
                    "link": "/finance/journaling",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 15,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 44,
                    "label": "Setup",
                    "icon": "",
                    "link": "",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 20,
                    "isActive": true,
                    "parent": null,
                    "subItems": [
                        {
                            "id": 45,
                            "label": "Account Groups",
                            "icon": "",
                            "link": "/finance/chartOfAccountsSetup",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 1,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 21,
                            "label": "Material Master",
                            "icon": "",
                            "link": "/inventory/material",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 1,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 22,
                            "label": "Bill of Material",
                            "icon": "",
                            "link": "/inventory/bom",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 2,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 46,
                            "label": "Chart of Accounts",
                            "icon": "",
                            "link": "/finance/chartOfAccounts",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 2,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 47,
                            "label": "Business Partners",
                            "icon": "",
                            "link": "/finance/businessPartners",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 3,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 23,
                            "label": "Warehouse Locations",
                            "icon": "",
                            "link": "/inventory/warehouses",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 3,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 48,
                            "label": "Bank Accounts",
                            "icon": "",
                            "link": "javascript: void(0);",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 4,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 49,
                            "label": "Expenditure Types",
                            "icon": "",
                            "link": "/finance/expenditureSetup",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 5,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 114,
                            "label": "Production Overheads",
                            "icon": "",
                            "link": "/finance/overheads-setup",
                            "parentId": 44,
                            "isUiElement": false,
                            "index": 100,
                            "isActive": true,
                            "subItems": []
                        }
                    ]
                },
                {
                    "id": 50,
                    "label": "Financial Statements",
                    "icon": "",
                    "link": "",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 30,
                    "isActive": true,
                    "parent": null,
                    "subItems": [
                        {
                            "id": 51,
                            "label": "Trial Balance",
                            "icon": "",
                            "link": "/finance/trial-balance",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 1,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 52,
                            "label": "Profit and Loss",
                            "icon": "",
                            "link": "/finance/profit-and-loss",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 2,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 53,
                            "label": "Balance Sheet",
                            "icon": "",
                            "link": "/finance/balance-sheet",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 3,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 54,
                            "label": "Cash Flow",
                            "icon": "",
                            "link": "/finance/cash-flow",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 4,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 77,
                            "label": "Cash Book",
                            "icon": "",
                            "link": "/finance/cash-book",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 4,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8360,
                            "label": "General Ledger",
                            "icon": null,
                            "link": "/finance/general-ledger",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 366,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8361,
                            "label": "Other Transactions",
                            "icon": null,
                            "link": "/finance/other-transactions",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 367,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8364,
                            "label": "Account Type",
                            "icon": null,
                            "link": "/finance/account-types",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 370,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8366,
                            "label": "Supplier Statement",
                            "icon": null,
                            "link": "/finance/supplier-statement",
                            "parentId": 50,
                            "isUiElement": false,
                            "index": 372,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8367,
                            "label": "Accounting Years",
                            "icon": null,
                            "link": "/finance/accounting-years",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 373,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8368,
                            "label": "Opening Balances",
                            "icon": null,
                            "link": "/finance/opening-balances",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 374,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8369,
                            "label": "Period End Run",
                            "icon": null,
                            "link": "/finance/period-end",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 375,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8370,
                            "label": "Year End Run",
                            "icon": null,
                            "link": "/finance/year-end",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 376,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8371,
                            "label": "Accounting Ratio Types",
                            "icon": null,
                            "link": "/finance/accounting-ratio-types",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 377,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8372,
                            "label": "Accounting Ratios",
                            "icon": null,
                            "link": "/finance/accounting-ratios",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 378,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8373,
                            "label": "Accounting Ratio Intepretations",
                            "icon": null,
                            "link": "/finance/accounting-ratio-intepretations",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 379,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8374,
                            "label": "Budgets",
                            "icon": null,
                            "link": "/finance/budgets",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 380,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8375,
                            "label": "Budget Scenarios",
                            "icon": null,
                            "link": "/finance/budget-scenarios",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 381,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8376,
                            "label": "Budget Distribution Methods",
                            "icon": null,
                            "link": "/finance/budget-distribution-methods",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 382,
                            "isActive": true,
                            "subItems": []
                        },
                        {
                            "id": 8379,
                            "label": "Departments",
                            "icon": null,
                            "link": "/finance/departments",
                            "parentId": 50,
                            "isUiElement": null,
                            "index": 385,
                            "isActive": true,
                            "subItems": []
                        }
                    ]
                },
                {
                    "id": 86,
                    "label": "Exchange Rates",
                    "icon": "",
                    "link": "/finance/exchange-rates",
                    "parentId": 42,
                    "isUiElement": false,
                    "index": 100,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 6130,
            "label": "Payroll",
            "icon": "",
            "link": "/payroll",
            "parentId": 20,
            "isUiElement": false,
            "index": 140,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 6131,
                    "label": "Employee",
                    "icon": "",
                    "link": "/payroll/employees",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 141,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 6132,
                    "label": "Leave",
                    "icon": "",
                    "link": "/payroll/leaves",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 142,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 6133,
                    "label": "Payslip",
                    "icon": "",
                    "link": "/payroll/payslips",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 143,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 6134,
                    "label": "Salary",
                    "icon": "",
                    "link": "/payroll/salaries",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 144,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7351,
                    "label": "Employee Grades",
                    "icon": null,
                    "link": "/payroll/employee-grades",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 357,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7352,
                    "label": "Payroll Run",
                    "icon": null,
                    "link": "/payroll/payrolls",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 358,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7353,
                    "label": "Payroll Periods",
                    "icon": null,
                    "link": "/payroll/payroll-periods",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 359,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7354,
                    "label": "Tax Categories",
                    "icon": null,
                    "link": "/payroll/tax-categories",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 360,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7355,
                    "label": "Service Provider Item",
                    "icon": null,
                    "link": "/payroll/serviceProviderItems",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 361,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 8355,
                    "label": "Payslip Item Types",
                    "icon": null,
                    "link": "/payroll/payslipItemTypes",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 362,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 8359,
                    "label": "Payslip By Payroll By Employee",
                    "icon": null,
                    "link": "/payroll/payslip-by-payroll-by-employee",
                    "parentId": 6130,
                    "isUiElement": false,
                    "index": 365,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 8378,
                    "label": "Overtime",
                    "icon": null,
                    "link": "/payroll/overtime",
                    "parentId": 6130,
                    "isUiElement": null,
                    "index": 384,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9381,
                    "label": "Payslip Items By Business Partner",
                    "icon": null,
                    "link": "/payroll/payslip-by-payroll-by-supplier",
                    "parentId": 6130,
                    "isUiElement": null,
                    "index": 388,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        }
    ],
    "links": [
        "",
        "/inventory/goods-receipt",
        "/purchase/quotations",
        "/purchase/requisitions",
        "/inventory/stock",
        "/purchase/orders",
        "/purchase/invoices",
        "/finance/other-transactions",
        "/finance/manage-purchases-invoice",
        "/inventory/sales-order",
        "/finance/bank-reconciliation",
        "/finance/asset-register",
        "/finance/debtors",
        "/finance/creditors",
        "/finance/accounting-periods",
        "/finance/journaling",
        "/finance/chartOfAccountsSetup",
        "/inventory/material",
        "/inventory/bom",
        "/finance/chartOfAccounts",
        "/finance/businessPartners",
        "/inventory/warehouses",
        "javascript: void(0);",
        "/finance/expenditureSetup",
        "/finance/overheads-setup",
        "/finance/trial-balance",
        "/finance/profit-and-loss",
        "/finance/balance-sheet",
        "/finance/cash-flow",
        "/finance/cash-book",
        "/finance/general-ledger",
        "/finance/account-types",
        "/finance/supplier-statement",
        "/finance/accounting-years",
        "/finance/opening-balances",
        "/finance/period-end",
        "/finance/year-end",
        "/finance/accounting-ratio-types",
        "/finance/accounting-ratios",
        "/finance/accounting-ratio-intepretations",
        "/finance/budgets",
        "/finance/budget-scenarios",
        "/finance/budget-distribution-methods",
        "/finance/departments",
        "/finance/exchange-rates",
        "/payroll",
        "/payroll/employees",
        "/payroll/leaves",
        "/payroll/payslips",
        "/payroll/salaries",
        "/payroll/employee-grades",
        "/payroll/payrolls",
        "/payroll/payroll-periods",
        "/payroll/tax-categories",
        "/payroll/serviceProviderItems",
        "/payroll/payslipItemTypes",
        "/payroll/payslip-by-payroll-by-employee",
        "/payroll/overtime",
        "/payroll/payslip-by-payroll-by-supplier"
    ]
};
jmenuTeacher = {
    "id": 586,
    "username": "pmuchingami@axissol.com",
    "password": "",
    "farmerId": 0,
    "companyId": 0,
    "transporterId": 0,
    "extensionOfficerId": 0,
    "studentId": 0,
    "employeeId": 249,
    "avatar": "NA",
    "fullName": "Presley  Muchingami",
    "roleId": 28,
    "roleName": "Teacher",
    "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1ODYiLCJ1bmlxdWVfbmFtZSI6InBtdWNoaW5nYW1pQGF4aXNzb2wuY29tIiwiZ3JvdXBzaWQiOiIyOCIsImdpdmVuX25hbWUiOiJUZWFjaGVyIiwiZmFtaWx5X25hbWUiOiJQcmVzbGV5TXVjaGluZ2FtaSIsIm5iZiI6MTcwMDMxODEyMSwiZXhwIjoxNzAwMzQ2OTIxLCJpYXQiOjE3MDAzMTgxMjF9.aqx-EVkge8JtnhZkqNGmRIGBsCUnEtyDjXEGFo4GGeroM6CwQ0kCk2dVvx98RP_Mx3fYw6e_XSHoVdu1UODoOw",
    "menus": [
        {
            "id": 7297,
            "label": "Teacher Details",
            "icon": null,
            "link": "/school-teachers",
            "parentId": 20,
            "isUiElement": true,
            "index": 303,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7317,
                    "label": "Teacher Lesson Plans",
                    "icon": null,
                    "link": "/school-teachers/teacher-lesson-plans",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 323,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7318,
                    "label": "Teacher Student Achievements",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-achievements",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 324,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7319,
                    "label": "Teacher Student Assignments",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-assignments",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 325,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7320,
                    "label": "Teacher Student Attendances",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-attendances",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 326,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7321,
                    "label": "Teacher Student Behaviours",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-behaviours",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 327,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7322,
                    "label": "Teacher Student Reports",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-reports",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 328,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7323,
                    "label": "Teacher Stuent Subjects",
                    "icon": null,
                    "link": "/school-teachers/teacher-student-subjects",
                    "parentId": 7297,
                    "isUiElement": false,
                    "index": 329,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 7328,
            "label": "Students Reports ",
            "icon": null,
            "link": "/school-student-reports",
            "parentId": 20,
            "isUiElement": true,
            "index": 334,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7331,
                    "label": "Outstanding Invoices By Student",
                    "icon": null,
                    "link": "/school-student-reports/outstanding-invoices-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 337,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7332,
                    "label": "Student Attendances By Student",
                    "icon": null,
                    "link": "/school-student-reports/student-attendances-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 338,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7333,
                    "label": "Student Attendances By Student By Term",
                    "icon": null,
                    "link": "/school-student-reports/student-attendances-by-one-student-by-term",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 339,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7334,
                    "label": "Student Report By Student By Term",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student-by-term",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 340,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7335,
                    "label": "Student Report By Student",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 341,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7336,
                    "label": "Time Table By Class",
                    "icon": null,
                    "link": "/school-student-reports/time-table-by-one-class",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 342,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9384,
                    "label": "Student Report",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student-millenial",
                    "parentId": 7328,
                    "isUiElement": null,
                    "index": 391,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 7329,
            "label": "Teacher Reports",
            "icon": null,
            "link": "/school-teacher-reports",
            "parentId": 20,
            "isUiElement": true,
            "index": 335,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7337,
                    "label": "Outstanding Invoices By Each Student",
                    "icon": null,
                    "link": "/school-teacher-reports/outstanding-invoices-by-each-student",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 343,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7338,
                    "label": "Outstanding Invoices By One Cohort",
                    "icon": null,
                    "link": "/school-teacher-reports/outstanding-invoices-by-one-cohort",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 344,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7339,
                    "label": "Pass Rate By One Cohort",
                    "icon": null,
                    "link": "/school-teacher-reports/pass-rate-by-one-cohort",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 345,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7340,
                    "label": "Pass Rate By One Subject",
                    "icon": null,
                    "link": "/school-teacher-reports/pass-rate-by-one-subject",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 346,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7341,
                    "label": "Payslips By One Employee",
                    "icon": null,
                    "link": "/school-teacher-reports/payslips-by-one-employee",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 347,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7342,
                    "label": "Student Attendances By Each Student",
                    "icon": null,
                    "link": "/school-teacher-reports/student-attendances-by-each-student",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 348,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7343,
                    "label": "Student Attendances By One Class By Date",
                    "icon": null,
                    "link": "/school-teacher-reports/student-attendances-by-one-class-by-date",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 349,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7344,
                    "label": "Student Report By Each Student By Term",
                    "icon": null,
                    "link": "/school-teacher-reports/student-report-by-each-student-by-term",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 350,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7345,
                    "label": "Student Reports By Each Student",
                    "icon": null,
                    "link": "/school-teacher-reports/student-reports-by-each-student",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 351,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7346,
                    "label": "Students By One Cohort",
                    "icon": null,
                    "link": "/school-teacher-reports/students-by-one-cohort",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 352,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7347,
                    "label": "Time table By Each Class",
                    "icon": null,
                    "link": "/school-teacher-reports/time-table-by-each-class",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 353,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7348,
                    "label": "Time Table By One Teacher",
                    "icon": null,
                    "link": "/school-teacher-reports/time-table-by-one-teacher",
                    "parentId": 7329,
                    "isUiElement": false,
                    "index": 354,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        }
    ],
    "links": [
        "/school-teachers",
        "/school-teachers/teacher-lesson-plans",
        "/school-teachers/teacher-student-achievements",
        "/school-teachers/teacher-student-assignments",
        "/school-teachers/teacher-student-attendances",
        "/school-teachers/teacher-student-behaviours",
        "/school-teachers/teacher-student-reports",
        "/school-teachers/teacher-student-subjects",
        "/school-student-reports",
        "/school-student-reports/outstanding-invoices-by-one-student",
        "/school-student-reports/student-attendances-by-one-student",
        "/school-student-reports/student-attendances-by-one-student-by-term",
        "/school-student-reports/student-report-by-one-student-by-term",
        "/school-student-reports/student-report-by-one-student",
        "/school-student-reports/time-table-by-one-class",
        "/school-student-reports/student-report-by-one-student-millenial",
        "/school-teacher-reports",
        "/school-teacher-reports/outstanding-invoices-by-each-student",
        "/school-teacher-reports/outstanding-invoices-by-one-cohort",
        "/school-teacher-reports/pass-rate-by-one-cohort",
        "/school-teacher-reports/pass-rate-by-one-subject",
        "/school-teacher-reports/payslips-by-one-employee",
        "/school-teacher-reports/student-attendances-by-each-student",
        "/school-teacher-reports/student-attendances-by-one-class-by-date",
        "/school-teacher-reports/student-report-by-each-student-by-term",
        "/school-teacher-reports/student-reports-by-each-student",
        "/school-teacher-reports/students-by-one-cohort",
        "/school-teacher-reports/time-table-by-each-class",
        "/school-teacher-reports/time-table-by-one-teacher"
    ]
};
jmenuStudent = {
    "id": 587,
    "username": "presleytinotendamuchingami@gmail.com",
    "password": "",
    "farmerId": 0,
    "companyId": 0,
    "transporterId": 0,
    "extensionOfficerId": 0,
    "studentId": 0,
    "employeeId": 0,
    "avatar": "NA",
    "fullName": "Tinotenda Muchingami",
    "roleId": 15,
    "roleName": "Student",
    "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1ODciLCJ1bmlxdWVfbmFtZSI6InByZXNsZXl0aW5vdGVuZGFtdWNoaW5nYW1pQGdtYWlsLmNvbSIsImdyb3Vwc2lkIjoiMTUiLCJnaXZlbl9uYW1lIjoiU3R1ZGVudCIsImZhbWlseV9uYW1lIjoiVGlub3RlbmRhTXVjaGluZ2FtaSIsIm5iZiI6MTcwMDMxODI1OCwiZXhwIjoxNzAwMzQ3MDU4LCJpYXQiOjE3MDAzMTgyNTh9.Nz4WSHk6b9202wvFub5UTU_s20cdu34qSKCP0BtR2tWwDaJ3EY8Ticg1iONGlHFna4ibON5Z3Hmy3WowQ9beMg",
    "menus": [
        {
            "id": 7296,
            "label": "Student Details",
            "icon": null,
            "link": "/school-students",
            "parentId": 20,
            "isUiElement": true,
            "index": 302,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7299,
                    "label": "Student Achievements",
                    "icon": null,
                    "link": "/school-students/student-achievements",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 305,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7300,
                    "label": "Student Assignments",
                    "icon": null,
                    "link": "/school-students/student-assignments",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 306,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7301,
                    "label": "Student Attendances",
                    "icon": null,
                    "link": "/school-students/student-attendances",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 307,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7302,
                    "label": "Student Behaviours",
                    "icon": null,
                    "link": "/school-students/student-behaviours",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 308,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7303,
                    "label": "Student Curriculum",
                    "icon": null,
                    "link": "/school-students/student-curriculums",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 309,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7304,
                    "label": "Student Profile",
                    "icon": null,
                    "link": "/school-students/student-details",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 310,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7305,
                    "label": "Student Enrollment",
                    "icon": null,
                    "link": "/school-students/student-enrollment",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 311,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7306,
                    "label": "Exam Candidacy",
                    "icon": null,
                    "link": "/school-students/student-exam-candidates",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 312,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7307,
                    "label": "Exam Enrollment",
                    "icon": null,
                    "link": "/school-students/student-exam-enrollments",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 313,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7308,
                    "label": "Exam Time Table",
                    "icon": null,
                    "link": "/school-students/student-exam-time-tables",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 314,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7309,
                    "label": "Student Reports",
                    "icon": null,
                    "link": "/school-students/student-reports",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 315,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7310,
                    "label": "School Events",
                    "icon": null,
                    "link": "/school-students/student-school-events",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 316,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7311,
                    "label": "Sporting House",
                    "icon": null,
                    "link": "/school-students/student-sport-houses",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 317,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7312,
                    "label": "Student Subjects",
                    "icon": null,
                    "link": "/school-students/student-subjects",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 318,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7313,
                    "label": "Student Submissions",
                    "icon": null,
                    "link": "/school-students/student-submissions",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 319,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7314,
                    "label": "Student Time Table",
                    "icon": null,
                    "link": "/school-students/student-time-tables",
                    "parentId": 7296,
                    "isUiElement": false,
                    "index": 320,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 7328,
            "label": "Students Reports ",
            "icon": null,
            "link": "/school-student-reports",
            "parentId": 20,
            "isUiElement": true,
            "index": 334,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7331,
                    "label": "Outstanding Invoices By Student",
                    "icon": null,
                    "link": "/school-student-reports/outstanding-invoices-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 337,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7332,
                    "label": "Student Attendances By Student",
                    "icon": null,
                    "link": "/school-student-reports/student-attendances-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 338,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7333,
                    "label": "Student Attendances By Student By Term",
                    "icon": null,
                    "link": "/school-student-reports/student-attendances-by-one-student-by-term",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 339,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7334,
                    "label": "Student Report By Student By Term",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student-by-term",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 340,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7335,
                    "label": "Student Report By Student",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 341,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7336,
                    "label": "Time Table By Class",
                    "icon": null,
                    "link": "/school-student-reports/time-table-by-one-class",
                    "parentId": 7328,
                    "isUiElement": false,
                    "index": 342,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9384,
                    "label": "Student Report",
                    "icon": null,
                    "link": "/school-student-reports/student-report-by-one-student-millenial",
                    "parentId": 7328,
                    "isUiElement": null,
                    "index": 391,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        }
    ],
    "links": [
        "/school-students",
        "/school-students/student-achievements",
        "/school-students/student-assignments",
        "/school-students/student-attendances",
        "/school-students/student-behaviours",
        "/school-students/student-curriculums",
        "/school-students/student-details",
        "/school-students/student-enrollment",
        "/school-students/student-exam-candidates",
        "/school-students/student-exam-enrollments",
        "/school-students/student-exam-time-tables",
        "/school-students/student-reports",
        "/school-students/student-school-events",
        "/school-students/student-sport-houses",
        "/school-students/student-subjects",
        "/school-students/student-submissions",
        "/school-students/student-time-tables",
        "/school-student-reports",
        "/school-student-reports/outstanding-invoices-by-one-student",
        "/school-student-reports/student-attendances-by-one-student",
        "/school-student-reports/student-attendances-by-one-student-by-term",
        "/school-student-reports/student-report-by-one-student-by-term",
        "/school-student-reports/student-report-by-one-student",
        "/school-student-reports/time-table-by-one-class",
        "/school-student-reports/student-report-by-one-student-millenial"
    ]
};
jmenuPrincipal = {
    "id": 49,
    "username": "emudzimu@yahoo.com",
    "password": "",
    "farmerId": 0,
    "companyId": 24,
    "transporterId": 0,
    "extensionOfficerId": 0,
    "studentId": 0,
    "employeeId": 106,
    "avatar": "AnyDesk.png",
    "fullName": "Edmond Mudzimu",
    "roleId": 31,
    "roleName": "Principal",
    "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI0OSIsInVuaXF1ZV9uYW1lIjoiZW11ZHppbXVAeWFob28uY29tIiwiZ3JvdXBzaWQiOiIzMSIsImdpdmVuX25hbWUiOiJQcmluY2lwYWwiLCJmYW1pbHlfbmFtZSI6IkVkbW9uZE11ZHppbXUiLCJuYmYiOjE3MDAzMTg0MzQsImV4cCI6MTcwMDM0NzIzNCwiaWF0IjoxNzAwMzE4NDM0fQ.MDpXWfYjF1REwsZKWRwYc7e7Ga2HRSvEKwVuwLja2wRcIAxegShjRijHxT16IQiQhJF8q-CoIUyt6NGJ6ul2fQ",
    "menus": [
        {
            "id": 7175,
            "label": "School Reports",
            "icon": "",
            "link": "",
            "parentId": 20,
            "isUiElement": false,
            "index": 189,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7176,
                    "label": "Employees By Institution",
                    "icon": "",
                    "link": "/school-reports/employees-by-school",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 190,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7178,
                    "label": "Classes By School",
                    "icon": "",
                    "link": "/school-reports/cohorts-by-school",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 192,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7179,
                    "label": "Students By Class",
                    "icon": "",
                    "link": "/school-reports/students-by-cohort",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 193,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7180,
                    "label": "Student Reports By Student",
                    "icon": "",
                    "link": "/school-reports/student-reports-by-student",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 194,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7181,
                    "label": "Student Attendances By Student",
                    "icon": "",
                    "link": "/school-reports/student-attendances-by-student",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 195,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7269,
                    "label": "Pass Rate By Cohort",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-cohort",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 276,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7270,
                    "label": "Pass Rate By Stream",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-stream",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 277,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7272,
                    "label": "Pass Rate By Streams",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-streams",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 278,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7273,
                    "label": "Pass Rate By Subject",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subject",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 279,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7274,
                    "label": "Pass Rate By Subjects",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subjects",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 280,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7275,
                    "label": "Pass Rate By Subjects By Streams",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subjects-by-streams",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 281,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7276,
                    "label": "Pass Rate By Cohorts",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-cohorts",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 282,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7281,
                    "label": "Time Table By Class",
                    "icon": null,
                    "link": "/school-reports/time-table-by-class",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 287,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7282,
                    "label": "Time Tables By Classes",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-classes",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 288,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7283,
                    "label": "Time Table By Teacher",
                    "icon": null,
                    "link": "/school-reports/time-table-by-teacher",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 289,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7284,
                    "label": "Time Tables By Teachers",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-teachers",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 290,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9382,
                    "label": "Time Table By Student",
                    "icon": null,
                    "link": "/school-reports/time-table-by-student",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 389,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9383,
                    "label": "Time Tables By Students",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-students",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 390,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9388,
                    "label": "Student Attendance By Class By Date",
                    "icon": null,
                    "link": "/school-reports/student-attendances-by-class-by-date",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 395,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 7298,
            "label": "Headmaster Details",
            "icon": null,
            "link": "/school-headmasters",
            "parentId": 20,
            "isUiElement": true,
            "index": 304,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7315,
                    "label": "Employee Attendances",
                    "icon": null,
                    "link": "/school-headmasters/employee-attendances",
                    "parentId": 7298,
                    "isUiElement": false,
                    "index": 321,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7316,
                    "label": "Employee Performances",
                    "icon": null,
                    "link": "/school-headmasters/employee-performances",
                    "parentId": 7298,
                    "isUiElement": false,
                    "index": 322,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9379,
                    "label": "Leave Approvals",
                    "icon": null,
                    "link": "/school-headmasters/leave-approvals",
                    "parentId": 7298,
                    "isUiElement": null,
                    "index": 386,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        }
    ],
    "links": [
        "",
        "/school-reports/employees-by-school",
        "/school-reports/cohorts-by-school",
        "/school-reports/students-by-cohort",
        "/school-reports/student-reports-by-student",
        "/school-reports/student-attendances-by-student",
        "/school-reports/pass-rate-by-cohort",
        "/school-reports/pass-rate-by-stream",
        "/school-reports/pass-rate-by-streams",
        "/school-reports/pass-rate-by-subject",
        "/school-reports/pass-rate-by-subjects",
        "/school-reports/pass-rate-by-subjects-by-streams",
        "/school-reports/pass-rate-by-cohorts",
        "/school-reports/time-table-by-class",
        "/school-reports/time-tables-by-classes",
        "/school-reports/time-table-by-teacher",
        "/school-reports/time-tables-by-teachers",
        "/school-reports/time-table-by-student",
        "/school-reports/time-tables-by-students",
        "/school-reports/student-attendances-by-class-by-date",
        "/school-headmasters",
        "/school-headmasters/employee-attendances",
        "/school-headmasters/employee-performances",
        "/school-headmasters/leave-approvals"
    ]
};
jmenuHeadmaster = {
    "id": 94,
    "username": "bgorah@gmail.com",
    "password": "",
    "farmerId": 0,
    "companyId": 0,
    "transporterId": 0,
    "extensionOfficerId": 0,
    "studentId": 0,
    "employeeId": 0,
    "avatar": null,
    "fullName": "Bradley gorah",
    "roleId": 30,
    "roleName": "Headmaster",
    "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI5NCIsInVuaXF1ZV9uYW1lIjoiYmdvcmFoQGdtYWlsLmNvbSIsImdyb3Vwc2lkIjoiMzAiLCJnaXZlbl9uYW1lIjoiSGVhZG1hc3RlciIsImZhbWlseV9uYW1lIjoiQnJhZGxleUdvcmFoIiwibmJmIjoxNzAwMzE4NDg3LCJleHAiOjE3MDAzNDcyODcsImlhdCI6MTcwMDMxODQ4N30.dG1-FW34t5u6az1ez3fYdG8q0krSk1Q1fmO_bgF3SRVfawgaaNVwj_XP28hq38ktShLT2rAnpy6g3qRL8j27Xg",
    "menus": [
        {
            "id": 7175,
            "label": "School Reports",
            "icon": "",
            "link": "",
            "parentId": 20,
            "isUiElement": false,
            "index": 189,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7176,
                    "label": "Employees By Institution",
                    "icon": "",
                    "link": "/school-reports/employees-by-school",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 190,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7178,
                    "label": "Classes By School",
                    "icon": "",
                    "link": "/school-reports/cohorts-by-school",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 192,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7179,
                    "label": "Students By Class",
                    "icon": "",
                    "link": "/school-reports/students-by-cohort",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 193,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7180,
                    "label": "Student Reports By Student",
                    "icon": "",
                    "link": "/school-reports/student-reports-by-student",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 194,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7181,
                    "label": "Student Attendances By Student",
                    "icon": "",
                    "link": "/school-reports/student-attendances-by-student",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 195,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7269,
                    "label": "Pass Rate By Cohort",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-cohort",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 276,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7270,
                    "label": "Pass Rate By Stream",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-stream",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 277,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7272,
                    "label": "Pass Rate By Streams",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-streams",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 278,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7273,
                    "label": "Pass Rate By Subject",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subject",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 279,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7274,
                    "label": "Pass Rate By Subjects",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subjects",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 280,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7275,
                    "label": "Pass Rate By Subjects By Streams",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-subjects-by-streams",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 281,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7276,
                    "label": "Pass Rate By Cohorts",
                    "icon": null,
                    "link": "/school-reports/pass-rate-by-cohorts",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 282,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7281,
                    "label": "Time Table By Class",
                    "icon": null,
                    "link": "/school-reports/time-table-by-class",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 287,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7282,
                    "label": "Time Tables By Classes",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-classes",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 288,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7283,
                    "label": "Time Table By Teacher",
                    "icon": null,
                    "link": "/school-reports/time-table-by-teacher",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 289,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7284,
                    "label": "Time Tables By Teachers",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-teachers",
                    "parentId": 7175,
                    "isUiElement": false,
                    "index": 290,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9382,
                    "label": "Time Table By Student",
                    "icon": null,
                    "link": "/school-reports/time-table-by-student",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 389,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9383,
                    "label": "Time Tables By Students",
                    "icon": null,
                    "link": "/school-reports/time-tables-by-students",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 390,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9388,
                    "label": "Student Attendance By Class By Date",
                    "icon": null,
                    "link": "/school-reports/student-attendances-by-class-by-date",
                    "parentId": 7175,
                    "isUiElement": null,
                    "index": 395,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        },
        {
            "id": 7298,
            "label": "Headmaster Details",
            "icon": null,
            "link": "/school-headmasters",
            "parentId": 20,
            "isUiElement": true,
            "index": 304,
            "isActive": true,
            "parent": {
                "id": 20,
                "label": "Setup",
                "icon": "",
                "link": "",
                "parentId": 12,
                "isUiElement": false,
                "index": 8,
                "isActive": true,
                "parent": {
                    "id": 12,
                    "label": "Dispatch Clerk",
                    "icon": "bx-tone",
                    "link": "",
                    "parentId": 20,
                    "isUiElement": true,
                    "index": 3,
                    "isActive": true,
                    "parent": null,
                    "subItems": null
                },
                "subItems": null
            },
            "subItems": [
                {
                    "id": 7315,
                    "label": "Employee Attendances",
                    "icon": null,
                    "link": "/school-headmasters/employee-attendances",
                    "parentId": 7298,
                    "isUiElement": false,
                    "index": 321,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 7316,
                    "label": "Employee Performances",
                    "icon": null,
                    "link": "/school-headmasters/employee-performances",
                    "parentId": 7298,
                    "isUiElement": false,
                    "index": 322,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                },
                {
                    "id": 9379,
                    "label": "Leave Approvals",
                    "icon": null,
                    "link": "/school-headmasters/leave-approvals",
                    "parentId": 7298,
                    "isUiElement": null,
                    "index": 386,
                    "isActive": true,
                    "parent": null,
                    "subItems": []
                }
            ]
        }
    ],
    "links": [
        "",
        "/school-reports/employees-by-school",
        "/school-reports/cohorts-by-school",
        "/school-reports/students-by-cohort",
        "/school-reports/student-reports-by-student",
        "/school-reports/student-attendances-by-student",
        "/school-reports/pass-rate-by-cohort",
        "/school-reports/pass-rate-by-stream",
        "/school-reports/pass-rate-by-streams",
        "/school-reports/pass-rate-by-subject",
        "/school-reports/pass-rate-by-subjects",
        "/school-reports/pass-rate-by-subjects-by-streams",
        "/school-reports/pass-rate-by-cohorts",
        "/school-reports/time-table-by-class",
        "/school-reports/time-tables-by-classes",
        "/school-reports/time-table-by-teacher",
        "/school-reports/time-tables-by-teachers",
        "/school-reports/time-table-by-student",
        "/school-reports/time-tables-by-students",
        "/school-reports/student-attendances-by-class-by-date",
        "/school-headmasters",
        "/school-headmasters/employee-attendances",
        "/school-headmasters/employee-performances",
        "/school-headmasters/leave-approvals"
    ]
};
  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private eventService: EventService,
    private menuItemService: MenuItemService,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private schoolEventService: SchoolEventService,
    private employeeService: EmployeeService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    this.element = document.documentElement;

    this.initialize();
//this.getSchoolEvents();
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }
getSchoolEvents()
{
  this.schoolEventService.getUrl('GetUpcomingSchoolEvents').subscribe((data)=>{
    this.schoolEvents = data;
    if(data != null)
    {
      this.showEvents = true;
    }
  })
}

getContractsExpiring()
{
  this.employeeService.getUrl('GetEmployeesByEmploymentType/Contract').subscribe((data)=>{
    this.contracts = data;
    if(data != null)
    {
      this.showContracts = true;
    }
  })
}
  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextSibling;
    if (nextEl && !nextEl.classList?.contains('show')) {
      const parentEl = event.target.parentNode;
      if (parentEl) { parentEl.classList?.remove('show'); }

      nextEl.classList?.add('show');
    } else if (nextEl) { nextEl.classList?.remove('show'); }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Topbar light
   */
  topbarLight() {
    document.body.setAttribute('data-topbar', 'light');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Set boxed width
   */
  boxedWidth() {
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-topbar', 'dark');
  }

  /**
   * Colored header
   */
  coloredHeader() {
    document.body.setAttribute('data-topbar', 'colored');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
   * Initialize
   */
  initialize(): void {
    // this.menuItems = MENU;
    // this.menuItemService.get().subscribe(
    //   data => {
    //     this.menuItems = data;
    //   }
    // );

 //this.menuItems = this.authFackservice.currentUserValue.menus;

   if(this.authFackservice.currentUserValue.roleName == 'Accountant')
   {
    this.menuItems = this.jmenuAccountant.menus;//this.authFackservice.currentUserValue.menus;
   }
    if(this.authFackservice.currentUserValue.roleName == 'Administrator')
   { this.menuItems = this.jmenuAdministrator.menus;//this.authFackservice.currentUserValue.menus;
  }
  if(this.authFackservice.currentUserValue.roleName == 'Teacher')
  { this.menuItems = this.jmenuTeacher.menus;//this.authFackservice.currentUserValue.menus;
 }
 if(this.authFackservice.currentUserValue.roleName == 'Student')
 { this.menuItems = this.jmenuStudent.menus;//this.authFackservice.currentUserValue.menus;
}
if(this.authFackservice.currentUserValue.roleName == 'Parent')
{ this.menuItems = this.jmenuStudent.menus;//this.authFackservice.currentUserValue.menus;
}
if(this.authFackservice.currentUserValue.roleName == 'Principal')
{ this.menuItems = this.jmenuPrincipal.menus;//this.authFackservice.currentUserValue.menus;
}
if(this.authFackservice.currentUserValue.roleName == 'Headmaster')
{ this.menuItems = this.jmenuHeadmaster.menus;//this.authFackservice.currentUserValue.menus;
}


    this.fullName = this.authFackservice.currentUserValue.fullName;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  viewProfile() {
    this.router.navigate(['/administration/users/profile']);
  }
}

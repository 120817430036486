import { Injectable } from '@angular/core';
import { Student } from '../../models/student';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { SchoolEvent } from '../../models/schoolEvent';

@Injectable({
  providedIn: 'root'
})
export class   SchoolEventService extends ApiService<SchoolEvent> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'SchoolEvents');
  }
}

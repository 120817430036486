import { Injectable } from '@angular/core';
import { Employee } from '../../models/employee';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends ApiService<Employee> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, 'Employees');
  }
}
